import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  DialogContentText,
} from "@mui/material";
import FormSectionHeader from "../../../components/FormSectionHeader";
import {
  changeCurrentWetlandFormValue,
  selectCurrentWetlandForm,
  selectCurrentWetlandSoilsComment,
} from "../../../api/services/wetlandforms/wetlandFormsSlice";
import { useTheme } from "@emotion/react";
import SummaryIsWetland from "./components/SummaryIsWetland";
import InfoDialogButton from "../../../features/infoDialogButton/InfoDialogButton";

export default function SummaryTab() {
  const theme = useTheme();
  const currentForm = useSelector(selectCurrentWetlandForm);
  const soilsComment = useSelector(selectCurrentWetlandSoilsComment);
  const dispatch = useDispatch();

  const handleStandardFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
  };

  const handleSelectFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.name, value: e.target.value }));
  };

  return (
    <Stack spacing={2}>
      <FormSectionHeader text={"Summary"} mt={0} mx={0} />
      <SummaryIsWetland />
      <TextField
        id="summaryComment"
        label="Summary Comment"
        fullWidth
        multiline
        rows={4}
        value={currentForm?.summaryComment ?? ""}
        onChange={handleStandardFormValueChange}
      />
      <TextField
        id="vegComment"
        label="Vegetation Comment"
        fullWidth
        multiline
        rows={2}
        value={currentForm?.vegComment ?? ""}
        onChange={handleStandardFormValueChange}
      />
      <TextField
        id="hydroComment"
        label="Hydrology Comment"
        fullWidth
        multiline
        rows={2}
        value={currentForm?.hydroComment ?? ""}
        onChange={handleStandardFormValueChange}
      />
      <TextField
        id="soilsComment"
        label="Soils Comment"
        fullWidth
        multiline
        rows={2}
        value={currentForm?.soilsComment ?? ""}
        onChange={handleStandardFormValueChange}
      />
      <FormSectionHeader text={"QA/QC (Quality Assurance/Quality Control)"} mt={0} mx={0} />
      <Grid container alignItems="center">
        <Grid item xs={5} sm={3}>
          <TextField
            id="qaqcer"
            label="QAQCer"
            fullWidth
            value={currentForm?.qaqcer ?? ""}
            onChange={handleStandardFormValueChange}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5} sm={3}>
          <FormControl>
            <InputLabel id="form-status-label">Form Status</InputLabel>
            <Select
              labelId="form-status-label"
              id="formStatus"
              name="formStatus"
              value={currentForm?.formStatus}
              label="Form Status"
              onChange={handleSelectFormValueChange}
            >
              <MenuItem value={"waiting"}>Waiting</MenuItem>
              <MenuItem value={"inProgress"} sx={{ color: "#FF9800" }}>
                In Progress
              </MenuItem>
              <MenuItem value={"complete"} sx={{ color: "#4caf50" }}>
                Complete
              </MenuItem>
            </Select>
          </FormControl>
          <InfoDialogButton
            title="Form Status"
            content={
              <Fragment>
                <DialogContentText>
                  "Form Status" is intended to represent what point the form is at in the data review/QAQC process. By
                  default this status is "Waiting" (waiting for review), and we suggest tracking the progress on form
                  revisions by switching this to "In Progress" when working on a form, or "Complete" when the data has
                  been thoroughly reviewed and finalized.
                </DialogContentText>
              </Fragment>
            }
          />
        </Grid>
        <Grid item xs={5} sm={3}>
          <Typography color={theme.palette.text.secondary}>Last Modified:</Typography>
          <Typography>{Date(Date.parse(currentForm?.lastModifiedTimeStamp ?? ''))}</Typography>
        </Grid>
      </Grid>
      <TextField
        id="qaqcNotes"
        label="QAQC Notes"
        fullWidth
        multiline
        rows={4}
        value={currentForm?.qaqcNotes ?? ""}
        onChange={handleStandardFormValueChange}
      />
    </Stack>
  );
}
