import axios from "axios";
import axiosInstance from '../../auth';

const spatialDataAxios = axios.create({
  baseURL: '',
  headers: {}
});

class SpatialDataApiService {

  // getCityCountyFromServer({ latitude, longitude }) {
  //   return axiosInstance.post(
  //     `/api/spatial/get_city_county_from_coordinates/`,
  //     {
  //       latitude: latitude,
  //       longitude: longitude,
  //     },
  //   );
  // }

  getCityCounty({ latitude, longitude }) {
    // When sending params in JSON post body, having issues with CORS headers, so params are just sent in the url itself.l
    return spatialDataAxios({
      method: 'post',
      // url: `https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Counties/FeatureServer/0/query?outFields=STATE_NAME,NAME&geometry=${longitude},${latitude}&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelWithin&outSR=4326&f=json`,
      url: `https://tigerweb.geo.census.gov/arcgis/rest/services/TIGERweb/State_County/MapServer/1/query?outFields=STATE,NAME&geometry=${longitude},${latitude}&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelWithin&outSR=4326&f=json`,
    });

    // axios has issues with setting no auth headers, causing CORS issues, so using built-in requests capability instead
    // return new Request('https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Counties/FeatureServer/0/query', 
    // {
    //   method: 'POST', 
    //   headers: {'Content-Type': 'application/json'},
    //   body: `{"outFields": "STATE_NAME,NAME", "geometry": "${longitude},${latitude}", "geometryType": "esriGeometryPoint", "inSR": "4326", "spatialRel": "esriSpatialRelWithin", "outSR": "4326", "f": "json"}`,
    // });
    // return spatialDataAxios({
    //   method: 'post',
    //   url: `FeatureServer/0/query/`,
    //   data: {
    //     'outFields': 'STATE_NAME,NAME',
    //     'geometry': `${longitude},${latitude}`,
    //     'geometryType': 'esriGeometryPoint',
    //     'inSR': '4326',
    //     'spatialRel': 'esriSpatialRelWithin',
    //     'outSR': '4326',
    //     'f': 'json'
    //   },
    //   // headers: {
    //   //   'Content-Type': 'application/json',
    //   //   accept: 'application/json',
    //   // },
    // });
  }

  getPLSS({ latitude, longitude }) {
    return spatialDataAxios({
      method: 'post',
      url: `https://gis.blm.gov/arcgis/rest/services/Cadastral/BLM_Natl_PLSS_CadNSDI/MapServer/2/query?outFields=PLSSID,FRSTDIVNO&geometry=${longitude},${latitude}&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelWithin&outSR=4326&f=json`,
    });
  }

  getAceSubregion({ latitude, longitude }) {
    return spatialDataAxios({
      method: 'post',
      url: `https://services7.arcgis.com/n1YM8pTrFmm7L4hs/arcgis/rest/services/coe_wetland_subregions/FeatureServer/0/query?outFields=ADS_SUB_NM&geometry=${longitude},${latitude}&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelWithin&outSR=4326&f=json`,
    });
  }

  getNwiClass({ latitude, longitude, outFields }) {
    // Use NWI_Wetland_Codes.ATTRIBUTE?
    // Old (as of 04/04/2024): query?outfields=ATTRIBUTE,WETLAND_TYPE
    let outFieldsString = outFields.join(',')  // Used in the url params, typically something like "ATTRIBUTE,WETLAND_TYPE"
    return spatialDataAxios({
      method: 'post',
      url: `https://www.fws.gov/wetlandsmapservice/rest/services/Wetlands/MapServer/0/query?outFields=${outFieldsString}&geometry=${longitude},${latitude}&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelWithin&outSR=4326&f=json`,
    });
  }

  getSoilMapUnit({ latitude, longitude }) {
    const query = `SELECT S.mukey, M.musym, M.muname FROM SDA_Get_Mukey_from_intersection_with_WktWgs84('point(${longitude} ${latitude})') as S, mapunit as m WHERE m.mukey = S.mukey`;
    return spatialDataAxios({
      method: 'post',
      url: `https://SDMDataAccess.sc.egov.usda.gov/Tabular/post.rest`,
      data: {'QUERY': query, 'FORMAT': 'JSON'},
    });
  }
}

export default new SpatialDataApiService();