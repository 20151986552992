import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  useCurrentLocationForNewFormLatLng: JSON.parse(localStorage.getItem('settings_current_location_for_coords')) ?? true,
  useStampedOverlayInPdfPhotos: JSON.parse(localStorage.getItem('settings_use_stamped_overlay_pdf_photos')) ?? true,
  useUpdated2024Pdf: JSON.parse(localStorage.getItem('settings_use_updated_2024_pdf')) ?? false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setUseCurrentLocationForCoords: (state, action) => {
      state.useCurrentLocationForNewFormLatLng = action.payload;
      localStorage.setItem('settings_current_location_for_coords', JSON.stringify(action.payload));
    },
    setUseStampedOverlayInPdfPhotos: (state, action) => {
      state.useStampedOverlayInPdfPhotos = action.payload;
      localStorage.setItem('settings_use_stamped_overlay_pdf_photos', JSON.stringify(action.payload));
    },
    setUseUpdated2024Pdf: (state, action) => {
      state.useUpdated2024Pdf = action.payload;
      localStorage.setItem('settings_use_updated_2024_pdf', JSON.stringify(action.payload));
    },
  },
});

export const { setUseCurrentLocationForCoords, setUseStampedOverlayInPdfPhotos, setUseUpdated2024Pdf } = settingsSlice.actions;

export const selectSettings = (state) => state.settings;
export const selectUseCurrentLocationForNewFormLatLng = (state) => state.settings.useCurrentLocationForNewFormLatLng;
export const selectUseStampedOverlayInPdfPhotos = (state) => state.settings.useStampedOverlayInPdfPhotos;
export const selectUseUpdated2024Pdf = (state) => state.settings.useUpdated2024Pdf;

export default settingsSlice.reducer;