import React from "react";
import { Card, Stack, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BugReportIcon from '@mui/icons-material/BugReport';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Box } from "@mui/system";

export const appChangelog = [
  {
    version: '1.8.0',
    changes: [
      {
        icon: <NewReleasesIcon />,
        text: 'Added an option to generate updated 2024 USACE PDF outputs for the NCNE region.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Fixed PDF image overlay options working when multiple PDFs being generated at one time.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Fixed several edge cases where invalid date/time values would cause the app to crash.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Fixed an issue in which an active sort or filter on the Projects field in the data table would sometimes cause the app to crash.',
      },
    ],
  },
  {
    version: '1.7.1',
    changes: [
      {
        icon: <BugReportIcon />,
        text: 'Performance improvements to PDF downloads.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Several small PDF format improvements.',
      },
    ],
  },
  {
    version: '1.7.0',
    changes: [
      {
        icon: <NewReleasesIcon />,
        text: 'Added purchase receipts to the Data Portal. In the Account/Organization tab, download your receipts from the "App Store Purchase Receipts" section.',
      },
      {
        icon: <ConstructionIcon />,
        text: 'Improvements and revisitions to the Account/Organization page layout.',
      },
      {
        icon: <ConstructionIcon />,
        text: 'Revised Projects tab to improve the readability, sorting, and filtering of projects.',
      },
      {
        icon: <ConstructionIcon />,
        text: 'Improved Hydrology and Hydric Soil indicator popups, allowing them to be viewed while still editing the data form.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Various small bug fixes and performance optimizations.',
      },
    ],
  },
  {
    version: '1.6.0',
    changes: [
      {
        icon: <NewReleasesIcon />,
        text: 'Added Batch Update functionality to update certain data fields for multiple data forms at once.',
      },
      {
        icon: <ConstructionIcon />,
        text: 'Revised naming conventions for duplicate PDFs/photos, preferring a more concise naming identifier to a lengthy unique ID.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Small tweaks and updates.',
      },
    ],
  },
  {
    version: '1.5.0',
    changes: [
      {
        icon: <ConstructionIcon />,
        text: 'Added additional photo attributes.',
      },
      {
        icon: <ConstructionIcon />,
        text: 'Added the ability to customize photo order and presence in generated PDFs.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Fixed issues with photos sometimes displaying sideways.',
      },
    ],
  },
  {
    version: '1.4.6',
    changes: [
      {
        icon: <ConstructionIcon />,
        text: 'Provided some additional clarity showing if projects or data forms can be edited.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Fixed several bugs regarding user project permissions and assignments.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Handled the edge case of attempting to navigate to a data form page with a blank sample name.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Several other small fixes and improvements.',
      },
    ],
  },
  {
    version: '1.4.5',
    changes: [
      {
        icon: <BugReportIcon />,
        text: 'Several small bug fixes and improvements.',
      },
    ],
  },
  {
    version: '1.4.4',
    changes: [
      {
        icon: <ConstructionIcon />,
        text: 'Added a filter by project option to the Map tab.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Several Safari browser fixes.',
      },
    ],
  },
  {
    version: '1.4.3',
    changes: [
      {
        icon: <ConstructionIcon />,
        text: 'When adding a new data form into a project, a popup is now presented to clarify the desired region.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Fixed a bug preventing hand-typed soil textures from properly saving.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Small bug fixes and improvements.',
      },
    ],
  },
  {
    version: '1.4.2',
    changes: [
      {
        icon: <ConstructionIcon />,
        text: 'Added a soil texture autocomplete dropdown.',
      },
      {
        icon: <ConstructionIcon />,
        text: 'Added several new landform and relief selection options.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Fixed a bug preventing PDF downloads from the Map tab.',
      },
    ],
  },
  {
    version: '1.4.1',
    changes: [
      {
        icon: <BugReportIcon />,
        text: 'Fixed an issue causing photos to not correctly append to PDF outputs when attempting to download multiple data forms at once.',
      },
    ],
  },
  {
    version: '1.4.0',
    changes: [
      {
        icon: <NewReleasesIcon />,
        text: 'Integrated projects into the data portal! Projects are an extra functionality that can be used to group data and restrict user access. This may introduce unexpected bugs and edge cases. If you encounter an issue, contact us at help@bioapp.us.',
      },
      {
        icon: <NewReleasesIcon />,
        text: 'Added the changelog tab.',
      },
      {
        icon: <NewReleasesIcon />,
        text: 'Implemented the ability to generate flattened data form PDF outputs.',
      },
      {
        icon: <ConstructionIcon />,
        text: 'Added the sample name to all tabs in the Excel data form download.',
      },
      {
        icon: <BugReportIcon />,
        text: 'Increased the maximum number of data forms shown on each page.',
      },
    ],
  },
];


export default function Changelog() {
  return (
    <Stack>
      <Card sx={{ margin: 4 }}>
        <Stack spacing={2} p={2}>
          <Typography component="h2" variant="h4">
            What's New in BioApp
          </Typography>
          {appChangelog.map((version) => <Box key={`changelog-container-${version.version}`}>
            <Typography component="h3" variant="h5">
              Version {version.version}
            </Typography>
            <List sx={{ mx: 2 }}>
            {version.changes.map((changeItem, index) => <ListItem key={`version-${version.version}-change-item-${index}`} disablePadding>
              <ListItemIcon>
                {changeItem.icon}
              </ListItemIcon>
              <ListItemText primary={changeItem.text} />
            </ListItem>)}
          </List>
          </Box>)}
        </Stack>
      </Card>
    </Stack>
  );
}
