import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentWetlandForm,
  changeCurrentWetlandFormValue,
  changeCurrentWetlandDate,
  fetchAllWetlandSpatialData,
} from "../../../api/services/wetlandforms/wetlandFormsSlice";
import {
  Box,
  Button,
  CircularProgress,
  Chip,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import { format } from "date-fns";
import FormSectionHeader from "../../../components/FormSectionHeader";
import { landformTypes, reliefTypes, slopeTypes } from "../../../utils/formConstants";
import { colorHydrology, colorSoils, colorVegetation } from "../../../utils/colors";
import TopographySectionPicker from "./components/TopographySectionPicker";
import { selectProjects } from "../../../api/services/projects/projectsSlice";
import ProjectSelectionAutocomplete from "./components/ProjectSelectionAutocomplete";

export default function GeneralsTab() {
  const currentForm = useSelector(selectCurrentWetlandForm);
  const surveyorsParsed = JSON.parse(currentForm?.surveyors ?? '[]');
  const projects = useSelector(selectProjects);
  const dispatch = useDispatch();

  const handleStandardFormValueChange = (e) => {
    // Based on the id of the element, set the corresponding value in the current form data
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
  };

  const handleSelectInputFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.name, value: e.target.value }));
  };

  const handleSwitchInputFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.checked }));
  };

  const [currentSurveyorInput, setCurrentSurveyorInput] = useState("");

  const handleAddSurveyor = () => {
    // Add current "surveyor" text field value (if not blank, and if not already in surveyors list), and reset surveyor text field
    const surveyors = JSON.parse(currentForm.surveyors);
    if (currentSurveyorInput !== "" && !surveyors.includes(currentSurveyorInput)) {
      surveyors.push(currentSurveyorInput);
      dispatch(changeCurrentWetlandFormValue({ name: "surveyors", value: JSON.stringify(surveyors) }));
      setCurrentSurveyorInput("");
    }
  };

  const handleRemoveSurveyor = (input) => {
    // Input argument is the string to be removed
    const surveyors = JSON.parse(currentForm.surveyors);
    const newSurveyors = surveyors.filter((item) => item !== input);
    dispatch(changeCurrentWetlandFormValue({ name: "surveyors", value: JSON.stringify(newSurveyors) }));
  };

  const [spatialDataLoading, setSpatialDataLoading] = useState(false);

  const handleFillAllSpatialData = () => {
    setSpatialDataLoading(true);
    dispatch(fetchAllWetlandSpatialData()).finally(() => {
      setSpatialDataLoading(false);
    });
  };

  return (
    <Grid container spacing={2} columnSpacing={4}>
      <Grid container item spacing={4}>
        <FormSectionHeader text={"Site Info"} />
      </Grid>
      <Grid container item spacing={4}>
        <Grid item xs={6} sm={4}>
          <TextField
            id="sampleName"
            label="Sample Name"
            fullWidth
            value={currentForm?.sampleName ?? ""}
            onChange={handleStandardFormValueChange}
            error={currentForm?.sampleName === "" || currentForm?.sampleName === "new"}
            helperText={(currentForm?.sampleName === "" || currentForm?.sampleName === "new") ? 'Invalid sample name' : null}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="dateTime"
              label="Sampling Date"
              value={currentForm?.dateTime ?? format(new Date(), "MM/dd/yyyy")}
              onChange={(newValue) => {
                if (newValue) {
                  dispatch(changeCurrentWetlandDate(format(newValue, "yyyy-MM-dd")));
                }
                // dispatch(changeCurrentWetlandFormValue({ name: "dateTime", value: format(newValue, "yyyy-MM-dd") + 'T12:00:00' }));
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container item columnSpacing={4} rowSpacing={1}>
        <Grid item xs={6} sm={4}>
          <TextField
            id="surveyors"
            label="Surveyors"
            fullWidth
            value={currentSurveyorInput ?? ""}
            onChange={(e) => setCurrentSurveyorInput(e.target.value)}
          />
        </Grid>
        <Grid item xs={1} sm={1} alignSelf="center">
          <Button aria-label="add surveyor" onClick={handleAddSurveyor} variant="outlined" color="secondary">
            Add
          </Button>
        </Grid>
        <Grid item alignSelf="center">
          {surveyorsParsed.map((item) => (
            <Chip key={`surveyor-${item}`} label={item} color="secondary" onDelete={() => handleRemoveSurveyor(item)} sx={{m: 1 }} />
          ))}
        </Grid>
      </Grid>
      <Grid container item spacing={4}>
        <Grid item xs={6} sm={4}>
          <TextField
            id="latitude"
            label="Latitude"
            fullWidth
            value={currentForm?.latitude ?? ""}
            onChange={handleStandardFormValueChange}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="longitude"
            label="Longitude"
            fullWidth
            value={currentForm?.longitude ?? ""}
            onChange={handleStandardFormValueChange}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="accuracy"
            label="Accuracy"
            fullWidth
            value={currentForm?.accuracy ?? ""}
            onChange={handleStandardFormValueChange}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="datum"
            label="Datum"
            fullWidth
            value={currentForm?.datum ?? ""}
            onChange={handleStandardFormValueChange}
          />
        </Grid>
      </Grid>
      <FormSectionHeader text={"Topography"} />
      <Grid container item spacing={2}>
        <Grid item xs={12} sm={4}>
          <TopographySectionPicker
            label="Landform"
            id="landform"
            value={currentForm?.landform ?? "None"}
            onChange={handleSelectInputFormValueChange}
            onTextFieldChange={handleStandardFormValueChange}
            selectChoices={landformTypes}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TopographySectionPicker
            label="Local Relief"
            id="relief"
            value={currentForm?.relief ?? ""}
            onChange={handleSelectInputFormValueChange}
            onTextFieldChange={handleStandardFormValueChange}
            selectChoices={reliefTypes}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TopographySectionPicker
            label="Slope (%)"
            id="slope"
            value={currentForm?.slope ?? ""}
            onChange={handleSelectInputFormValueChange}
            onTextFieldChange={handleStandardFormValueChange}
            selectChoices={slopeTypes}
          />
        </Grid>
      </Grid>

      <FormSectionHeader text={"Site Circumstances"} />
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="normalCircumstances"
                  checked={currentForm?.normalCircumstances ?? true}
                  onChange={handleSwitchInputFormValueChange}
                />
              }
              label="Are 'Normal Circumstances' present?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="climHydConditionsTypical"
                  checked={currentForm?.climHydConditionsTypical ?? true}
                  onChange={handleSwitchInputFormValueChange}
                />
              }
              label="Are climatic/hydrologic conditions on the site typical for this time of year?"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container item spacing={0}>
        <Grid item xs={12}>
          <Typography>Are any of the following significantly disturbed?</Typography>
        </Grid>
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="vegSignifDisturb"
                  checked={currentForm?.vegSignifDisturb ?? false}
                  onChange={handleSwitchInputFormValueChange}
                  sx={{
                    color: colorVegetation,
                    "&.Mui-checked": {
                      color: colorVegetation,
                    },
                  }}
                  inputProps={{
                    "aria-label": "Vegetation significantly disturbed checkbox",
                  }}
                />
              }
              label="Vegetation"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="hydroSignifDisturb"
                  checked={currentForm?.hydroSignifDisturb ?? false}
                  onChange={handleSwitchInputFormValueChange}
                  sx={{
                    color: colorHydrology,
                    "&.Mui-checked": {
                      color: colorHydrology,
                    },
                  }}
                  inputProps={{
                    "aria-label": "Hydrology significantly disturbed checkbox",
                  }}
                />
              }
              label="Hydrology"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="soilSignifDisturb"
                  checked={currentForm?.soilSignifDisturb ?? false}
                  onChange={handleSwitchInputFormValueChange}
                  sx={{
                    color: colorSoils,
                    "&.Mui-checked": {
                      color: colorSoils,
                    },
                  }}
                  inputProps={{
                    "aria-label": "Soils significantly disturbed checkbox",
                  }}
                />
              }
              label="Soils"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container item spacing={0}>
        <Grid item xs={12}>
          <Typography>Are any of the following naturally problematic?</Typography>
        </Grid>
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="vegNaturallyProb"
                  checked={currentForm?.vegNaturallyProb ?? false}
                  onChange={handleSwitchInputFormValueChange}
                  sx={{
                    color: colorVegetation,
                    "&.Mui-checked": {
                      color: colorVegetation,
                    },
                  }}
                  inputProps={{
                    "aria-label": "Vegetation naturally problematic checkbox",
                  }}
                />
              }
              label="Vegetation"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="hydroNaturallyProb"
                  checked={currentForm?.hydroNaturallyProb ?? false}
                  onChange={handleSwitchInputFormValueChange}
                  sx={{
                    color: colorHydrology,
                    "&.Mui-checked": {
                      color: colorHydrology,
                    },
                  }}
                  inputProps={{
                    "aria-label": "Hydrology naturally problematic checkbox",
                  }}
                />
              }
              label="Hydrology"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="soilNaturallyProb"
                  checked={currentForm?.soilNaturallyProb ?? false}
                  onChange={handleSwitchInputFormValueChange}
                  sx={{
                    color: colorSoils,
                    "&.Mui-checked": {
                      color: colorSoils,
                    },
                  }}
                  inputProps={{
                    "aria-label": "Soils naturally problematic checkbox",
                  }}
                />
              }
              label="Soils"
            />
          </FormGroup>
        </Grid>
      </Grid>

      <FormSectionHeader text={"Spatial Info"} />
      <Box sx={{ position: "relative" }} alignItems="center" alignSelf="end">
        <Button
          disabled={spatialDataLoading}
          aria-label="fetch spatial data"
          onClick={handleFillAllSpatialData}
          variant="outlined"
          color="secondary"
        >
          Fetch Spatial Data
        </Button>
        {spatialDataLoading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
      <Grid container item spacing={4}>
        <Grid item xs={12} sm={4}>
          <ProjectSelectionAutocomplete />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="projectSite"
            label="Project/Site"
            fullWidth
            disabled={currentForm?.project ? true : false}
            value={currentForm?.projectSite ?? ""}
            onChange={handleStandardFormValueChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="applicantOwner"
            label="Applicant/Owner"
            fullWidth
            disabled={currentForm?.project ? true : false}
            value={currentForm?.applicantOwner ?? ""}
            onChange={handleStandardFormValueChange}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={4}>
        <Grid item xs={12} sm={4}>
          <TextField
            id="cityCounty"
            label="City/County"
            fullWidth
            value={currentForm?.cityCounty ?? ""}
            onChange={handleStandardFormValueChange}
            error={currentForm?.usStateAndCityCountyFetchError === true}
            helperText={(currentForm?.usStateAndCityCountyFetchError === true) ? 'Error fetching City/County' : null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="usState"
            label="State"
            fullWidth
            value={currentForm?.usState ?? ""}
            onChange={handleStandardFormValueChange}
            error={currentForm?.usStateAndCityCountyFetchError === true}
            helperText={(currentForm?.usStateAndCityCountyFetchError === true) ? 'Error fetching State' : null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="secTownRange"
            label="Section, Township, Range"
            fullWidth
            value={currentForm?.secTownRange ?? ""}
            onChange={handleStandardFormValueChange}
            error={currentForm?.secTownRangeFetchError === true}
            helperText={(currentForm?.secTownRangeFetchError === true) ? 'Error fetching PLSS Data' : null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="subregion"
            label="Subregion"
            fullWidth
            value={currentForm?.subregion ?? ""}
            onChange={handleStandardFormValueChange}
            error={currentForm?.subregionFetchError === true}
            helperText={(currentForm?.subregionFetchError === true) ? 'Error fetching Subregion' : null}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            id="soilMUName"
            label="Soil Map Unit"
            fullWidth
            value={currentForm?.soilMUName ?? ""}
            onChange={handleStandardFormValueChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="nwiClass"
            label="NWI Classification"
            fullWidth
            value={currentForm?.nwiClass ?? ""}
            onChange={handleStandardFormValueChange}
            error={currentForm?.nwiClassFetchError === true}
            helperText={(currentForm?.nwiClassFetchError === true) ? 'Error fetching data from FWS NWI service' : null}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
