import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, CircularProgress, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import {
  generateWetlandPdf,
  generateWetlandPdfsFromSelection,
  selectPdfsDownloadStatusLoading,
  setShouldCancelPdfsDownload,
} from "../../../api/services/filedownloads/fileDownloadsSlice";
import {
  selectSelectedWetlandFormIds,
  selectSelectedWetlandForms,
} from "../../../api/services/wetlandforms/wetlandFormsSlice";

export default function GeneratePDFsButton({ buttonText, useSmallInfoVariant }) {
  const dispatch = useDispatch();
  const loading = useSelector(selectPdfsDownloadStatusLoading);
  const selectedFormIds = useSelector(selectSelectedWetlandFormIds);
  const selectedForms = useSelector(selectSelectedWetlandForms);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    // If currently loading, clicking should cancel the download. Otherwise, proceed with opening the popup
    if (loading) {
      dispatch(setShouldCancelPdfsDownload(true));
    } else {
      dispatch(setShouldCancelPdfsDownload(false));
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGeneratePdfs = (event, flattenOutput, include_photos = false) => {
    event.preventDefault();
    if (selectedFormIds.length === 1) {
      dispatch(
        generateWetlandPdf({
          id: selectedFormIds[0],
          sampleName: selectedForms[0].sampleName,
          flattenOutput: flattenOutput,
          include_photos: include_photos,
        })
      );
    } else {
      dispatch(
        generateWetlandPdfsFromSelection({
          ids: selectedFormIds,
          flattenOutput: flattenOutput,
          include_photos: include_photos,
        })
      );
    }
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ position: "relative" }}>
        <Button
          disabled={!selectedFormIds.length}
          edge="end"
          aria-label="generate pdfs from selection"
          aria-controls={open ? "generate-pdfs-options-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          color={loading && selectedFormIds.length > 1 ? "warning" : useSmallInfoVariant ? "info" : null}
          size={useSmallInfoVariant ? "small" : null}
          disableElevation
          onClick={handleClick}
          startIcon={
            loading && selectedFormIds.length > 1 ? (
              <CircularProgress
                size={18}
                sx={{
                  color: "white",
                  top: "50%",
                  left: "50%",
                }}
              />
            ) : (
              !useSmallInfoVariant && <PictureAsPdfIcon />
            )
          }
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        >
          {loading && selectedFormIds.length > 1 ? "Cancel" : buttonText}
          {useSmallInfoVariant && <PictureAsPdfIcon />}
        </Button>
        {/* {(loading && selectedFormIds.length === 1) && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )} */}
      </Box>
      <Menu
        id="generate-pdfs-customized-menu"
        MenuListProps={{
          "aria-labelledby": "generate-pdfs-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => handleGeneratePdfs(e, false)} disableRipple>
          <ListItemIcon>
            <EditIcon fontSize="small" color="warning" />
          </ListItemIcon>
          <ListItemIcon />
          <ListItemText sx={{ color: "text.primary" }}>Editable PDFs</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleGeneratePdfs(e, false, true)} disableRipple>
          <ListItemIcon>
            <EditIcon fontSize="small" color="warning" />
          </ListItemIcon>
          <ListItemIcon>
            <PhotoLibraryIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText sx={{ color: "text.primary" }}>Editable PDFs with Photo Pages</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleGeneratePdfs(e, true)} disableRipple>
          <ListItemIcon>
            <EditOffIcon fontSize="small" color="text.secondary" />
          </ListItemIcon>
          <ListItemIcon />
          <ListItemText sx={{ color: "text.primary" }}>Flattened PDFs</ListItemText>
          <Tooltip title="Flattened PDFs contain no editable form fields, which may resolve issues merging multiple PDFs">
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <InfoIcon fontSize="small" />
            </Typography>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={(e) => handleGeneratePdfs(e, true, true)} disableRipple>
          <ListItemIcon>
            <EditOffIcon fontSize="small" color="text.secondary" />
          </ListItemIcon>
          <ListItemIcon>
            <PhotoLibraryIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText sx={{ color: "text.primary" }}>Flattened PDFs with Photo Pages</ListItemText>
          <Tooltip title="Flattened PDFs contain no editable form fields, which may resolve issues merging multiple PDFs">
            <Typography variant="body2" pl={2} sx={{ color: "text.secondary" }}>
              <InfoIcon pl={2} fontSize="small" />
            </Typography>
          </Tooltip>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
